<template>
    <div class="LimitationsView ViewWrapper">
        <clv-head-meta :title="$t('Service Limitations')"></clv-head-meta>
        <view-header>
            <h1>{{ $t('Service Limitations') }}</h1>
        </view-header>
        <view-container>

            <div class="mt-4"></div>

            <div class="row">
                <div class="col-12 col-md-8">
                    <div class="gk-card">
                        <table class="table">
                            <thead>
                            <tr>
                                <th></th>
                                <th>{{ $t('Limit') }}</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr>
                                <td>{{ $t('Max Recipients per Email Campaign') }}</td>
                                <td>{{ limitations.maxRecipientsPerEmailCampaign }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('Max Recipients per SMS Campaign') }}</td>
                                <td>{{ limitations.maxRecipientsPerSmsCampaign }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('Max People per Import') }}</td>
                                <td>{{ limitations.maxPeoplePerImport }}</td>
                            </tr>
                            <tr>
                                <td>{{ $t('Max Communication Subscriptions per Import') }}</td>
                                <td>{{ limitations.maxCommunicationSubscriptionsPerImport }}</td>
                            </tr>
                            <tr>
                                <td colspan="2">
                                    <router-link :to="{ name: 'quotas' }">{{ $t('Service Quotas') }}</router-link>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </view-container>
    </div>
</template>

<script>
/**
 * Service limitations view.
 *
 * @author Dimitris Gkoulis
 */
export default {
    name: 'LimitationsView',
    data () {
        return {
            limitations: null
        };
    },
    beforeMount () {
        this.limitations = this.$store.getters['application/tenantAccount'].details.limitations;
    }
};
</script>
